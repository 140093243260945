<template>
  <div class="">
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn
          class="transparent"
          rounded
          outlined
          v-on="on"
          v-model="$i18n.locale"
        >
          <v-img
            v-if="$i18n.locale == 'en'"
            src="../assets/flags/en.png"
            alt="English Flag"
            max-height="25"
            max-width="30"
            contain
          ></v-img>
          <v-img
            v-else-if="$i18n.locale == 'nb'"
            src="../assets/flags/no.png"
            alt="English Flag"
            max-height="19"
            max-width="30"
            contain
          ></v-img>
          <v-img
            v-else-if="$i18n.locale == 'fi'"
            src="../assets/flags/fi.png"
            alt="English Flag"
            max-height="19"
            max-width="30"
            contain
          ></v-img>
          <v-img
            v-else-if="$i18n.locale == 'gr'"
            src="../assets/flags/gr.png"
            alt="English Flag"
            max-height="19"
            max-width="30"
            contain
          ></v-img>
          <v-img
            v-else-if="$i18n.locale == 'de'"
            src="../assets/flags/de.png"
            alt="English Flag"
            max-height="19"
            max-width="30"
            contain
          ></v-img>
          <v-img
            v-else-if="$i18n.locale == 'tr'"
            src="../assets/flags/tr.png"
            alt="English Flag"
            max-height="19"
            max-width="30"
            contain
          ></v-img>
          {{ $i18n.locale }}
          <v-icon class="pl-3">mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            @click="setLanguage(item)"
          >
            <v-list-item-content class="text-center">
              <v-list-item-title v-model="$i18n.locale">
                <v-row>
                  <v-col cols="5">
                    <v-img
                      v-if="item.text == 'en'"
                      :src="item.flag"
                      alt="English Flag"
                      max-height="25"
                      max-width="30"
                      contain
                    ></v-img>
                    <v-img
                      v-else-if="item.text == 'nb'"
                      :src="item.flag"
                      alt="Norwegian Flag"
                      max-height="19"
                      max-width="30"
                      contain
                    ></v-img>
                    <v-img
                      v-else-if="item.text == 'fi'"
                      :src="item.flag"
                      alt="Finnish Flag"
                      max-height="19"
                      max-width="30"
                      contain
                    ></v-img>
                    <v-img
                      v-else-if="item.text == 'gr'"
                      :src="item.flag"
                      alt="Greek Flag"
                      max-height="19"
                      max-width="30"
                      contain
                    ></v-img>
                    <v-img
                      v-else-if="item.text == 'de'"
                      :src="item.flag"
                      alt="German Flag"
                      max-height="19"
                      max-width="30"
                      contain
                    ></v-img>
                    <v-img
                      v-else-if="item.text == 'tr'"
                      :src="item.flag"
                      alt="Turkish Flag"
                      max-height="19"
                      max-width="30"
                      contain
                    ></v-img>
                  </v-col>
                  <v-col cols="2">
                    {{ item.text }}
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "SelectLocale",
  data() {
    return {
      langs: ["en", "nb", "fi", "el", "de", "tr"],
      languageDialog: false,
      selectedLanguage: this.$store.getters.getAppLanguage,
      items: [
        { text: "en", flag: require("@/assets/flags/en.png") },
        { text: "nb", flag: require("@/assets/flags/no.png") },
        { text: "fi", flag: require("@/assets/flags/fi.png") },
        { text: "gr", flag: require("@/assets/flags/gr.png") },
        { text: "de", flag: require("@/assets/flags/de.png") },
        { text: "tr", flag: require("@/assets/flags/tr.png") },
      ],
    };
  },
  methods: {
    setLanguage(item) {
      if (item.text == "en") {
        this.$i18n.locale = "en";
        this.$store.commit("setAppLanguage", "en");
      } else if (item.text == "nb") {
        this.$i18n.locale = "nb";
        this.$store.commit("setAppLanguage", "nb");
      } else if (item.text == "fi") {
        this.$i18n.locale = "fi";
        this.$store.commit("setAppLanguage", "fi");
      } else if (item.text == "gr") {
        this.$i18n.locale = "gr";
        this.$store.commit("setAppLanguage", "gr");
      } else if (item.text == "de") {
        this.$i18n.locale = "de";
        this.$store.commit("setAppLanguage", "de");
      } else if (item.text == "tr") {
        this.$i18n.locale = "tr";
        this.$store.commit("setAppLanguage", "tr");
      }
      location.reload();
    },
    changeLanguage() {
      this.languageDialog = true;
      localStorage.setItem("lang", localeCode);
    },
  },
};
</script>
