<template>
  <div>
    <ContactUs ref="OpenContactForm"></ContactUs>
    <!-- Navbar -->
    <v-app-bar app color="white" class="navbarStylingPhone" style="height: 112px; box-shadow: 0px 2px 20px #00000029">
      <!-- <v-img class="mt-8 ml-10" src="img/EVOLVE.png"  max-height="80" max-width="80" contain></v-img> -->
      <v-btn icon @click="toggleNavbar()" class="mt-10 ml-0 mr-2">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <a href="/">
        <img id="" style="height: 85px; transition: 0.3s; margin-top: 46px" src="/img/edwlogowhite.png" alt="" />
      </a>
      <v-spacer class="mt-1"></v-spacer>
      <v-btn icon @click="$refs.OpenContactForm.openContactDialog()" class="mt-10 ml-2 mr-0">
        <v-icon>mdi-email-outline</v-icon>
      </v-btn>
    </v-app-bar>
    <!-- Navbar -->
    <v-navigation-drawer v-model="navDrawer" app>
      <a href="/">
        <!-- <v-img  class="mt-2 mb-2" src="img/EVOLVE.png"  height="60" contain></v-img> -->
        <img class="mt-3 ml-3" style="height: 105px; transition: 0.3s" src="/img/edwlogowhite.png" alt="" />
      </a>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item v-for="(item, index) in pageNavigation" :key="index" :to="item.location" class="mt-1">
          <!-- <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon> -->
          <v-list-item-content>
            <v-list-item-title class="caBody pb-1 pt-1 pl-2" style="font-size: 19px">{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item @click="$refs.OpenContactForm.openContactDialog()">
          <v-list-item-icon>
            <v-icon>mdi-email-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <!-- <v-list-item href="https://app.followup.prios.no/#/login" target="_blank" class="mt-1"> -->
        <!-- <v-list-item-icon>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-icon> -->
        <!-- <v-list-item-content>
            <v-list-item-title class="caBody pb-1 pl-2" style="font-size: 19px">Login</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
      <!-- <v-btn text href="https://app.followup.prios.no/#/login" target="_blank">Login</v-btn> -->
    </v-navigation-drawer>
  </div>
</template>

<script>
import ContactUs from "@/components/Global/ContactUs.vue";
export default {
  components: {
    ContactUs,
  },
  data() {
    return {
      navDrawer: false,
      expand: true,
      pageNavigation: [
        { name: "Home", location: "/", icon: "" },
        // { name: "About", location: "/about", icon: "" },
        { name: "Partners", location: "/partners", icon: "" },
        { name: "News", location: "/news", icon: "" },
        // { name: "RSS", location: "/rss", icon: "" },
        // { name: "Training Modules", location: "/trainingmodules", icon: "" },
        { name: "Resources", location: "/resources", icon: "" },
        { name: "Contact", location: "/contact", icon: "" },
      ],
    };
  },

  methods: {
    // Toggles the sidebar on and offs
    toggleNavbar() {
      if (this.navDrawer == true) {
        this.navDrawer = false;
      } else {
        this.navDrawer = true;
      }
    },
  },
};
</script>

<style>
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap"); */
.navbarStylingPhone {
  /* height: 150px !important; */
  /* padding: 35px 0 50px 0; */
  /* background-color: #ffffff !important; */
  box-shadow: 0px 2px 20px #00000029;
  transition: 0.2s;
}
.navbarStylingPhone::after {
  height: 6px;
  background-color: var(--brand-color-main);
  top: calc(100% - 18px);
  width: calc(100% - 24px);
  margin: auto 12px auto 12px;
  content: "";
  position: absolute;
  border-radius: 100px;
}
/* .fontTitle {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
}
.fontSubTitle {
  font-family: "Poppins", sans-serif;
  font-size: 19px;
}
.fontContent {
  font-size: 19px;
  font-family: "Source Sans Pro", sans-serif;
}
p {
  font-family: "Source Sans Pro", sans-serif;
} */
</style>
