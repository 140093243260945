<template>
  <v-footer color="#FFFFFF" class="footerStyling pt-12 pb-12">
    <v-row style="width: 100%" no-gutters>
      <v-col cols="12" align="center">
        <v-col
          cols="12"
          align="left"
          xl="10"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          class=""
        >
          <v-row>
            <v-col align="left" cols="3">
              <v-img
                class="mt-10"
                :src="euLogo"
                alt="Funded by the european union banner"
                width="auto"
                contain
              ></v-img>
            </v-col>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="6"
              xs="12"
              align-self="center"
              class="pt-12"
            >
              <v-row no-gutters>
                <v-col cols="auto">
                  <p class="mr-2 mt-1">
                    <span class="caCaption"
                      >{{ $t("footer.disclaimer3") }}
                    </span>
                    <a
                      href="https://creativecommons.org/licenses/by-sa/4.0/?ref=chooser-v1"
                      target="_blank"
                      rel="noopener noreferrer"
                      class="text-blue-500 font-bold hover:underline"
                    >
                      CC BY-SA 4.0
                    </a>
                  </p>
                </v-col>
                <v-col cols="auto">
                  <img
                    src="img/footerIconsRow.png"
                    alt="Project Logo"
                    style="max-height: 30px; width: auto"
                  />
                </v-col>
              </v-row>
              <p class="caCaption">{{ $t("footer.disclaimer1") }}</p>
              <p class="caCaption">{{ $t("footer.disclaimer2") }}</p>
            </v-col>
            <v-col align="right" cols="3" class="d-flex-shrink">
              <v-img
                class="mt-5"
                src="img/EDWlogowhite.png"
                alt="Empower disadvantaged women logo"
                max-height="180"
                max-width="180"
                contain
              ></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      languageSelected: "",
      euLogos: [
        {
          language: "en",
          src: require("@/assets/footer/CoFundedEnglish.png"),
        },
        {
          language: "de",
          src: require("@/assets/footer/CoFundedGermany.png"),
        },
        {
          language: "fi",
          src: require("@/assets/footer/CoFundedFinnish.png"),
        },
        {
          language: "gr",
          src: require("@/assets/footer/CoFundedGreek.png"),
        },
        {
          language: "nb",
          src: require("@/assets/footer/CoFundedNorwegian.png"),
        },
        {
          language: "tr",
          src: require("@/assets/footer/CoFundedTurkey.png"),
        },
      ],
    };
  },
  methods: {
    // Get language from localsStorage and set it to the data property languageSelected. if not found set it to 'en'
    getLanguage() {
      this.languageSelected = localStorage.getItem("language") || "en";
    },
  },
  mounted() {
    this.getLanguage();
  },
  // Run a computed property to get the languageSelected and return the correct logo
  computed: {
    euLogo() {
      return this.euLogos.find(
        (logo) => logo.language === this.languageSelected
      );
    },
  },
};
</script>

<style scoped>
.footerStyling {
  /* padding: 35px 0 50px 0; */
  background-color: #ffffff !important;
  box-shadow: 0px 2px 20px #00000029 !important;
}
.footerStyling::before {
  height: 6px;
  background-color: var(--brand-color-secondary);
  bottom: calc(100% - 18px);
  width: calc(100% - 24px);
  margin: auto auto auto -4px;
  content: "";
  position: absolute;
  border-radius: 100px;
}

.cursorStyle {
  cursor: pointer;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.dividerStyling {
  background-color: #329d9c;
  border-radius: 4px;
  opacity: 1;
  padding: 2px;
  margin-bottom: 20px;
  margin-top: 5px;
}

.footerText {
  font-family: "Lato", sans-serif;
  text-align: left;
  letter-spacing: 0px;
  color: #6a6a6a;
  opacity: 1;
  font-size: 14px;
}
</style>
