<template>
  <div :class="$vuetify.breakpoint.lgAndDown ? 'pr-3 pl-3' : ''">
    <NewsDialog ref="openingNewsDialog"></NewsDialog>
    <v-row>
      <v-col align="center" class="mt-15 mb-15">
        <v-col cols="12" xl="8" lg="8" md="8" sm="12" xs="12">
          <v-row>
            <v-col align="left" cols="12" class="mt-15 pb-0">
              <p class="caHeadline">{{ $t("contact.contact-title") }}</p>
            </v-col>
            <!-- CONTACT FORM -->
            <v-row class="pa-3 mb-11">
              <v-col cols="12">
                <v-card
                  elevation="0"
                  style="background-color: #f5f5f5; border-radius: 7px"
                >
                  <v-card-title>
                    <span class="caSubtitle ml-3 mt-2">{{
                      $t("contact.contact-form-title")
                    }}</span>
                    <v-spacer />
                    <!-- <v-btn color="error" icon @click="closeContact">
                      <v-icon>mdi-close</v-icon>
                    </v-btn> -->
                  </v-card-title>
                  <v-card-text>
                    <!-- <v-divider class="mb-4"></v-divider> -->
                    <v-form ref="form" v-model="isContactFormValid">
                      <v-container>
                        <v-text-field
                          v-model="contactFormData.name"
                          :label="$t('contact.name')"
                          name="name"
                          :rules="[rules.requiredName]"
                          outlined
                          dense
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model="contactFormData.email"
                          name="email"
                          :label="$t('contact.email')"
                          :rules="[rules.requiredEmail, rules.emailRequirement]"
                          outlined
                          dense
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model="contactFormData.subject"
                          name="subject"
                          :label="$t('contact.subject')"
                          :rules="[rules.requiredField]"
                          outlined
                          dense
                          required
                        ></v-text-field>
                        <v-textarea
                          v-model="contactFormData.message"
                          name="message"
                          :label="$t('contact.message')"
                          :rules="[rules.requiredField]"
                          outlined
                          auto-grow
                          counter
                          required
                        ></v-textarea>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-col
                    align="left"
                    class="ml-4 pb-6"
                    style="margin-top: -40px"
                  >
                    <button
                      :disabled="!isContactFormValid"
                      @click="postContactForm()"
                      class="mainButton"
                    >
                      <div class="mainButtonInsetDiv pr-6 pl-6">
                        <div class="mainButtonText">
                          {{ $t("contact.submit") }}
                        </div>
                      </div>
                    </button>
                  </v-col>
                  <!-- <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="clearForm"><v-icon>mdi-close</v-icon></v-btn>
                    <v-btn color="success" icon :disabled="!isContactFormValid" @click="postContactForm()"><v-icon>mdi-send</v-icon></v-btn>
                  </v-card-actions> -->
                </v-card>
              </v-col>
            </v-row>
            <!-- FINLAND -->
            <!-- <v-col cols="12" align="center">
              <v-col cols="12" align="center" style="background-color: #f5f5f5; border-radius: 7px" class="pa-10 mt-1">
                <p class="caSubtitle mb-2">{{ $t("partners.learnmera-country") }}</p>
                <v-img src="/img/EDWswoosh.svg" style="width: 108px; height: 15px" class="mb-3"></v-img>
                <v-col cols="12" align="left">
                  <p class="caBody mb-6">
                    <b>Veronica Gelfgren</b> — {{ $t("contact.directorat-prefix") }} Learnmera Oy{{ $t("contact.directorat-affix") }} —
                    <b>{{ $t("contact.phone") }}: </b> <a href="tel:35845165454">358 4516 5454</a> — <b>Sähköposti: </b>
                    <a href="mailto:veronica@learnmera.com">veronica@learnmera.com</a> /
                    <a href="mailto:veronica.gelfgren@gmail.com">veronica.gelfgren@gmail.com</a>
                  </p>
                  <v-divider></v-divider>
                  <p class="caBody mt-6 mb-6">
                    <b>Susanne Ehn</b> — {{ $t("contact.projectmanager-prefix") }} Learnmera Oy{{ $t("contact.projectmanager-affix") }} —
                    <b>{{ $t("contact.phone") }}:</b> <a href="tel:46702210095"> 467 0221 0095</a> — <b>Sähköposti: </b>
                    <a href="mailto:susanne@learnmera.com">susanne@learnmera.com</a> /
                    <a href="mailto:susanneehn0@gmail.com">susanneehn0@gmail.com</a>
                  </p>
                  <v-divider></v-divider>
                  <p class="caBody mt-6 mb-6">
                    <b>Ellen Olsen</b> — {{ $t("contact.projectadmin-prefix") }} Learnmera Oy{{ $t("contact.projectadmin-affix") }} —
                    <b>{{ $t("contact.phone") }}:</b> <a href="tel:447833794867"> 4478 3379 4867</a> —
                    <b>Email: </b>
                    <a href="mailto:ellen@learnmera.com">ellen@learnmera.com</a> /
                    <a href="mailto:ellen.olsen111@gmail.com">ellen.olsen111@gmail.com</a>
                  </p>
                </v-col>
              </v-col>
            </v-col> -->
            <!-- TURKEY -->
            <!-- <v-col cols="12" align="center">
              <v-col cols="12" align="center" style="background-color: #f5f5f5; border-radius: 7px" class="pa-10 mt-15">
                <p class="caSubtitle mb-2">{{ $t("partners.muavs-country") }}</p>
                <v-img src="/img/EDWswoosh.svg" style="width: 108px; height: 15px" class="mb-3"></v-img>
                <v-col cols="12" align="left">
                  <p class="caBody mb-6">
                    <b>Gürkan Temiz</b> — {{ $t("contact.directorat-prefix") }} Mersin University Anamur MYO{{ $t("contact.directorat-affix") }} —
                    <b>{{ $t("contact.phone") }}: </b><a href="tel:905352024236">905 352 024 236</a> — <b>Sähköposti: </b
                    ><a href="mailto:gurkantemiz@mersin.edu.tr">gurkantemiz@mersin.edu.tr</a>
                  </p>
                  <v-divider></v-divider>
                  <p class="caBody mt-6 mb-6">
                    <b>Bilge Kaan Ercan</b> — {{ $t("contact.projectmanager-prefix") }} Mersin University Anamur MYO{{
                      $t("contact.projectmanager-affix")
                    }}
                    — <b>{{ $t("contact.phone") }}: </b><a href="tel:905453936713">905 453 936 713</a> — <b>Sähköposti: </b>
                    <a href="mailto:bilgekaanercan@mersin.edu.tr">bilgekaanercan@mersin.edu.tr</a>
                  </p>
                  <v-divider></v-divider>
                  <p class="caBody mt-6 mb-6">
                    <b>Fatma Ülker</b> — {{ $t("contact.projectadmin-prefix") }} Mersin University Anamur MYO{{ $t("contact.projectadmin-affix") }} —
                    <b>{{ $t("contact.phone") }}: </b><a href="mailto:905056547516">905 056 547 516</a> — <b>Sähköposti: </b>
                    <a href="mailto:fatmaulker33@gmail.com">fatmaulker33@gmail.com</a>
                  </p>
                </v-col>
              </v-col>
            </v-col> -->
            <!-- NORWAY -->
            <!-- <v-col cols="12" align="center">
              <v-col cols="12" align="center" style="background-color: #f5f5f5; border-radius: 7px" class="pa-10 mt-15">
                <p class="caSubtitle mb-2">{{ $t("partners.prios-country") }}</p>
                <v-img src="/img/EDWswoosh.svg" style="width: 108px; height: 15px" class="mb-3"></v-img>
                <v-col cols="12" align="left">
                  <p class="caBody">
                    <b>Chriss Rune Nerland</b> — {{ $t("contact.directorat-prefix") }} Prios Vest AS{{ $t("contact.directorat-affix") }} —
                    <b>{{ $t("contact.phone") }}: </b><a href="tel:(0047)4747687575">(0047)47 476 87 575</a> — <b>Sähköposti: </b
                    ><a href="mailto:chriss@prios.no">chriss@prios.no</a>
                  </p>
                </v-col>
              </v-col>
            </v-col> -->
            <!-- GREECE -->
            <!-- <v-col cols="12" align="center">
              <v-col cols="12" align="center" style="background-color: #f5f5f5; border-radius: 7px" class="pa-10 mt-15">
                <p class="caSubtitle mb-2">{{ $t("partners.dcot-country") }}</p>
                <v-img src="/img/EDWswoosh.svg" style="width: 108px; height: 15px" class="mb-3"></v-img>
                <v-col cols="12" align="left">
                  <p class="caBody mb-6">
                    <b>Acilleas Kostoulas</b> — {{ $t("contact.headofeu-prefix") }} AKETH{{ $t("contact.headofeu-affix") }} —
                    <b>{{ $t("contact.phone") }}: </b><a href="tel:302431024697">302 431 024 697</a> — <b>Sähköposti: </b
                    ><a href="mailto:a.kostoulas@aketh.gr">a.kostoulas@aketh.gr</a>
                  </p>
                  <v-divider></v-divider>
                  <p class="caBody mt-6">
                    <b>Gulsah Aksoy Tzafolias</b> — {{ $t("contact.projectmanager-prefix") }} AKETH{{ $t("contact.projectmanager-affix") }} —
                    <b>{{ $t("contact.phone") }}: </b><a href="tel:306944248689">306 944 248 689</a> — <b>Sähköposti: </b
                    ><a href="mailto:gaketh.aksoy@gmail.com">gaketh.aksoy@gmail.com</a>
                  </p>
                </v-col>
              </v-col>
            </v-col> -->
            <!-- AUSTRIA -->
            <!-- <v-col cols="12" align="center" class="mb-15">
              <v-col cols="12" align="center" style="background-color: #f5f5f5; border-radius: 7px" class="pa-10 mt-15">
                <p class="caSubtitle mb-2">{{ $t("partners.vaev-country") }}</p>
                <v-img src="/img/EDWswoosh.svg" style="width: 108px; height: 15px" class="mb-3"></v-img>
                <v-col cols="12" align="left">
                  <p class="caBody mb-6">
                    <b>Magdalena Miladinovic</b> — {{ $t("contact.seniorresearcher-prefix") }} VAEV{{ $t("contact.seniorresearcher-affix") }} —
                    <b>{{ $t("contact.phone") }}: </b><a href="tel:302431024697">302 431 024 697</a> — <b>Sähköposti: </b
                    ><a href="mailto:a.kostoulas@aketh.gr">a.kostoulas@aketh.gr</a>
                  </p>
                  <v-divider></v-divider>
                  <p class="caBody mt-6">
                    <b>Tatiana Migaesi</b> — {{ $t("contact.researcher-prefix") }} VAEV{{ $t("contact.researcher-affix") }} —
                    <b>{{ $t("contact.phone") }}: </b><a href="tel:436609647896">436 609 647 896</a> — <b>Sähköposti: </b
                    ><a href="mailto:researcher3.at.vie@gmail.com">researcher3.at.vie@gmail.com</a>
                  </p>
                </v-col>
              </v-col>
            </v-col> -->
          </v-row>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accessKey: process.env.VUE_APP_API_KEY,
      contactDialog: false,
      contactToast: false,
      isContactFormValid: true,
      // The formObject to send into the database
      contactFormData: {
        name: "",
        email: "",
        message: "",
        subject: "",
        sentFrom: "Empowering Disadvantaged Women",
      },
      // Rules for the contact Form
      rules: {
        requiredField: (value) =>
          !!value || this.$t("contact.this field is required"),
        requiredName: (value) =>
          !!value || this.$t("contact.your name is required"),
        requiredEmail: (value) =>
          !!value || this.$t("a proper email address is required"),
        emailRequirement: (value) =>
          /.+@.+\..+/.test(value) ||
          this.$t("a proper email address is required"),
      },
    };
  },

  methods: {
    // Clear Form data
    clearForm() {
      this.$refs.form.reset();
    },

    // Message sent through Toast
    messageSent() {
      this.contactToast = true;
    },

    // Post Function (Toast, Reset and Close dialog)
    postContactForm() {
      const ContactForm = {
        name: this.contactFormData.name,
        email: this.contactFormData.email,
        subject: this.contactFormData.subject,
        message: this.contactFormData.message,
        sentFrom: this.contactFormData.sentFrom,
      };
      console.log("Sending Contact Form", ContactForm);
      // this.messageSent();
      // this.closeContact();
      // Post it to DB
      this.$http
        .post(
          "https://app.followup.prios.no/api/crm/contactform/prios",
          ContactForm,
          { headers: { Tempaccess: this.accessKey } }
        )
        .then(() => {
          this.messageSent();
          this.closeContact();
        });
    },
  },
};
</script>

<style scoped></style>
