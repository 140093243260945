<template>
  <div :class="$vuetify.breakpoint.lgAndDown ? 'pr-3 pl-3' : ''">
    <v-row v-if="$vuetify.breakpoint.smAndUp">
      <!-- ===== About Company ===== -->
      <v-col cols="12" align="center" class="mt-15">
        <v-col cols="12" align="left" xl="8" lg="10" md="12" sm="12" xs="12" :class="$vuetify.breakpoint.smAndDown ? 'mt-0' : 'mt-15'">
          <v-row>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              align-self="center"
              class="pt-0"
              order-xl="first"
              order-lg="first"
              order-md="first"
              order-sm="last"
              order-xs="last"
            >
              <p class="caHeadline mb-3">About the project</p>
              <p class="caBody mb-1">
                We aim to provide special learning opportunities to low-skilled adults and women, to encourage women into education by removing
                location and time restrictions, and by increasing women's economic empowerment/basic skills and competencies through remote education.
              </p>
              <v-chip class="aboutChip mr-6 mt-6">Some key words</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">To describe the project</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">Some chips</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">describe the project</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">Some key words</v-chip>
            </v-col>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              class="pt-0"
              order-xl="last"
              order-lg="last"
              order-md="last"
              order-sm="first"
              order-xs="first"
            >
              <div>
                <v-img src="/img/about-1.jpg"></v-img>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- THE GOAL -->
      <v-col cols="12" align="center" class="mt-15">
        <v-col cols="12" align="left" xl="8" lg="10" md="12" sm="12" xs="12" class="mt-15">
          <v-row>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              align-self="center"
              class="pt-0"
              order-xl="first"
              order-lg="first"
              order-md="first"
              order-sm="last"
              order-xs="last"
            >
              <div>
                <v-img src="/img/about-2.jpg"></v-img>
              </div>
            </v-col>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              align-self="center"
              class="pt-0"
              order-xl="last"
              order-lg="last"
              order-md="last"
              order-sm="first"
              order-xs="first"
            >
              <p class="caTitle mb-2">Our focus</p>
              <v-img src="/img/EDWswoosh.svg" style="width: 108px; height: 15px" class="mb-3"></v-img>
              <p class="caBody mt-4">
                In particular, our project focuses on housewives, immigrant or refugee women with multiple disabilities who want to be educated but
                couldn't, who are responsible for housework, sick and elderly care, who are out of the education system, who cannot work. The project
                focuses on creating online course modules in an innovative method to increase the employability of women with fewer opportunities.
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- FOR WHOM -->
      <v-col cols="12" align="center" class="mt-15">
        <v-col cols="12" align="left" xl="8" lg="10" md="12" sm="12" xs="12" class="mt-15">
          <v-row>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              align-self="center"
              class="pt-0"
              order-xl="first"
              order-lg="first"
              order-md="first"
              order-sm="last"
              order-xs="last"
            >
              <p class="caTitle mb-2">For whom</p>
              <v-img src="/img/EDWswoosh.svg" style="width: 108px; height: 15px" class="mb-3"></v-img>
              <p class="caBody mt-4">
                The target group of the project consists of women with multiple disadvantages (aged 20-65). The ultimate goal of the project is to
                ensure the social and economic inclusion of the target group by increasing their basic level skills and qualifications,
                digital/financial literacy and entrepreneurship skills.
              </p>
            </v-col>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              class="pt-0"
              order-xl="last"
              order-lg="last"
              order-md="last"
              order-sm="first"
              order-xs="first"
            >
              <div>
                <v-img src="/img/about-3.jpg"></v-img>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- </v-row> -->
      <!-- WHY -->
      <!-- <v-col cols="12" align="center" class="mt-15 mb-15 pb-15">
        <v-col cols="12" align="left" xl="8" lg="10" md="12" sm="12" xs="12" class="mt-15">
          <v-row>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              align-self="center"
              class="pt-0"
              order-xl="last"
              order-lg="last"
              order-md="last"
              order-sm="first"
              order-xs="first"
            >
              <div>
                <v-img src="/img/about-4.jpg"></v-img>
              </div>
            </v-col>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              align-self="center"
              class="pt-0"
              order-xl="last"
              order-lg="last"
              order-md="last"
              order-sm="first"
              order-xs="first"
            >
              <p class="caTitle">Why</p>
              <p class="caBody mt-6">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam ipsa voluptates dicta autem itaque eligendi reiciendis voluptatem.
                Rerum quo praesentium natus ab cumque, esse at laudantium tenetur odit quaerat nam!
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-col> -->

      <!-- ===== POINTS ===== -->
      <v-col cols="12" align="center" class="mb-15">
        <v-col cols="12" align="center" xl="6" lg="8" md="10" sm="11" xs="12" style="background-color: #f5f5f5" class="pa-10 mt-15">
          <p class="caTitle mb-2">Our goal(?)</p>
          <v-img src="/img/EDWswoosh.svg" style="width: 108px; height: 15px" class="mb-3"></v-img>
          <v-col cols="12" align="left">
            <p class="caBody">
              • To empower women in career, entrepreneurship, digital skills and financial literacy through education and ensuring their participation
              in social and economic life
            </p>
            <p class="caBody">• To create a strong social education network for women.</p>
            <p class="caBody">
              • To encourage women to develop themselves, their economic empowerment, financial and social inclusion by providing professional and
              personal development trainings.
            </p>
            <p class="caBody">• Including women in the social network with user-friendly mobile applications and e-content.</p>
            <p class="caBody">• Contributing to digital transformation in the field of adult education</p>
            <p class="caBody">• To create a user-friendly e learning platform</p>
            <p class="caBody">
              • To give the participants the awareness of Europeanness and European citizenship and reshape our network via creating a bond.
            </p>
          </v-col>
          <button class="mainButton">
            <div class="mainButtonInsetDiv pr-6 pl-6">
              <div class="mainButtonText">Contact us</div>
            </div>
          </button>
        </v-col>
      </v-col>
    </v-row>

    <!-- =============== PHONE =============== -->

    <v-row v-if="$vuetify.breakpoint.xsOnly">
      <!-- ===== About Project ===== -->
      <v-col cols="12" align="center" class="mt-15">
        <v-col cols="12" align="left" xl="8" lg="10" md="12" sm="12" xs="12" :class="$vuetify.breakpoint.smAndDown ? 'mt-0' : 'mt-15'">
          <v-row>
            <v-col cols="12" class="pa-0">
              <div>
                <v-img src="/img/aboutgreen.png"></v-img>
              </div>
            </v-col>
            <v-col cols="12">
              <p class="caHeadline">About CA4GF</p>
              <p class="caBody mt-6">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam ipsa voluptates dicta autem itaque eligendi reiciendis voluptatem.
                Rerum quo praesentium natus ab cumque, esse at laudantium tenetur odit quaerat nam!
              </p>
              <v-chip class="aboutChip mr-6 mt-6">Some key words</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">To describe the project</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">Some chips</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">describe the project</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">Some key words</v-chip>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- THE GOAL -->
      <v-col cols="12" align="center" class="mt-5">
        <v-col cols="12" align="left" xl="8" lg="10" md="12" sm="12" xs="12" class="mt-15">
          <v-row>
            <v-col cols="12" align-self="center" class="pa-0">
              <div>
                <v-img src="/img/aboutyellow.png"></v-img>
              </div>
            </v-col>
            <v-col cols="12" align-self="center" class="pt-0">
              <p class="caTitle">The focus</p>
              <p class="caBody mt-6">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam ipsa voluptates dicta autem itaque eligendi reiciendis voluptatem.
                Rerum quo praesentium natus ab cumque, esse at laudantium tenetur odit quaerat nam!
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- FOR WHOM -->
      <v-col cols="12" align="center" class="mt-5">
        <v-col cols="12" align="left" xl="8" lg="10" md="12" sm="12" xs="12" class="mt-15">
          <v-row>
            <v-col cols="12" class="pa-0">
              <div>
                <v-img src="/img/aboutblue.png"></v-img>
              </div>
            </v-col>
            <v-col cols="12" align-self="center" class="pt-0">
              <p class="caTitle">For whom</p>
              <p class="caBody mt-6">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam ipsa voluptates dicta autem itaque eligendi reiciendis voluptatem.
                Rerum quo praesentium natus ab cumque, esse at laudantium tenetur odit quaerat nam!
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- WHY -->
      <v-col cols="12" align="center" class="mt-5 mb-15 pb-15">
        <v-col cols="12" align="left" xl="8" lg="10" md="12" sm="12" xs="12" class="mt-15">
          <v-row>
            <v-col cols="12" align-self="center" class="pa-0">
              <div>
                <v-img src="/img/aboutred.png"></v-img>
              </div>
            </v-col>
            <v-col cols="12" align-self="center" class="pt-0">
              <p class="caTitle">Why</p>
              <p class="caBody mt-6">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam ipsa voluptates dicta autem itaque eligendi reiciendis voluptatem.
                Rerum quo praesentium natus ab cumque, esse at laudantium tenetur odit quaerat nam!
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LanguageTest from "@/components/TestAndDevelopment/LanguageTest.vue";
import SelectLocale from "@/components/TestAndDevelopment/SelectLocale.vue";
export default {
  name: "About",
  components: {
    LanguageTest,
    SelectLocale,
  },
  data() {
    return {
      keyBoxes: [
        { title: "Some key words" },
        { title: "To describe the project" },
        { title: "To make it more digestible" },
        { title: "To describe the project" },
        { title: "Some key words" },
      ],
      results: [
        {
          title: "Output 1 - Classroom Activities Guide - How Can Teachers Involve Technology, Games, Art and Nature into mathematics?",
          description: `This Planning Guide for Teachers contains over 60 classroom activities designed to support the teaching of Maths available to download.
This Guide will set out the activities in order to facilitate both teacher and school planning for Math teaching. 
The guide will be divided into chapters, for different age groups. 
Activities included in it are suitable for different class levels from Primary level to secondary l level, learning objectives for each activity, opportunities for applying the skills of: questioning, observing, predicting, investigating and experimenting, estimating and measuring, analyzing, exploring, planning, making and evaluating. 
`,
        },
        {
          title: "Output 2 - M@THGAN Game",
          description: `The created M@THGAN game will assist in developing a positive attitude towards mathematics and learning. 
This output focuses on the development of the game with a printed and online version for students to learn mathematical principles through playing a serious game.
The game will be playable in single mode and in multiplayer mode.
`,
        },
        {
          title: "Output 3 - MOOC M@THGAN",
          description: `6 MOOCs will be created and uploaded to project eLearning platform. 
Those are:
-	  Module 1: Effective Ways of Teaching Mathematics. 
-	  Module 2: Model of Classroom Activities Handbook. 
-	  Module 3: Using Games and Gamification as educational tools. The usage of M@THGAN game. 
-	  Module 4: Computational thinking and using coding in Mathematics teaching. 
-	  Module 5: Open, creative and visual Mathematics. Art In MATH. 
-	  Module 6: Outdoor Mathematics. Nature in Math.
`,
        },
      ],
    };
  },
};
</script>

<style scoped>
/* General Page */
.homePageWidth {
  width: 90vw;
  margin: auto auto;
}

/* About Company */
.aboutTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 48px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.aboutDescription {
  color: #6a6a6a;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 18px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.aboutKeyBoxes {
  margin: 0px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  text-align: center;
  background-color: #e0f1ff;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
}
.aboutImageBox {
  width: 100%;
  height: 100%;
  background-color: #d1d1d1;
  opacity: 1;
}

/* Goal */
.goalTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.goalDescription {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.goalImage {
  width: 100%;
  height: 100%;
  background-color: #d1d1d1;
  opacity: 1;
}

/* For Whom */
.whomTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whomDescription {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whomImage {
  width: 100%;
  height: 100%;
  background-color: #d1d1d1;
  opacity: 1;
}
.whomBackground {
  background-color: #f2f2f2;
  opacity: 1;
}

/* Why */
.whyTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 34px;
  color: #434343;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whyDescription {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #6a6a6a;
  opacity: 1;
  letter-spacing: 0px;
  text-align: left;
}
.whyImage {
  width: 100%;
  height: 100%;
  background-color: #d1d1d1;
  opacity: 1;
}
.aboutChip {
  font-size: var(--caption-size) !important;
  font-weight: var(--caption-weight);
  font-family: var(--body-font);
  border-radius: 100px !important;
  background-color: #65009a15 !important;
  padding: 20px 24px !important;
  pointer-events: none;
}
</style>
