import nb from "./lang.nb.json";
import en from "./lang.en.json";
import de from "./lang.de.json";
import fi from "./lang.fi.json";
import tr from "./lang.tr.json";
import gr from "./lang.gr.json";

export default {
  en,
  nb,
  de,
  fi,
  tr,
  gr
};
