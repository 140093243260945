import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
const vuetify = new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#65009a',
          secondary: '#8dc73f',
          accent: '#4398e8',
          error: '#b71c1c',
        },
      },
    },
  })
export default new Vuetify({
});
