<template>
  <div :class="$vuetify.breakpoint.lgAndDown ? 'pr-3 pl-3' : ''">
    <v-col cols="12" align="center" class="mb-15">
      <v-col cols="12" align="left" xl="8" lg="10" md="12" sm="12" xs="12" :class="$vuetify.breakpoint.smAndDown ? 'mt-0' : 'mt-15'">
        <v-row>
          <v-col cols="12" xl="10" lg="10" md="10" sm="12" xs="12" class="mt-12">
            <!-- <v-card class="ma-0 pa-0 box" flat> -->
            <p class="ma-0 pa-0 caHeadline">Partners</p>
            <!-- </v-card> -->
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12" v-for="(item, index) in projectPartners" :key="index" class="mb-4">
            <v-card class="cardBorder ma-2 pa-7 px-2 mb-0" height="100%">
              <v-row>
                <v-col cols="12">
                  <v-card height="90px" flat class="d-flex" :href="item.link" target="_blank">
                    <v-img class="mt-5" :src="item.image" contain />
                  </v-card>
                </v-col>
                <v-col class="pb-0 mb-0" cols="12">
                  <p class="pl-3 partnerTitle">
                    {{ item.title }}
                    <span>
                      <v-btn icon :href="item.link" target="_blank">
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                    </span>
                  </p>
                </v-col>
                <v-col v-if="selectedIndex !== index" cols="12" class="ma-0 pa-0 pl-3 pr-3">
                  <p class="pl-3 pr-3 partnerDescriptionPre">{{ item.description }}</p>
                </v-col>
                <v-expand-transition>
                  <div v-show="index === selectedIndex">
                    <v-divider></v-divider>
                    <v-card-text>
                      <p class="partnerDescriptionPost">{{ item.description }}</p>
                      <p class="partnerDescriptionPost">{{ item.vaevpoint1 }}</p>
                      <p class="partnerDescriptionPost">{{ item.vaevpoint2 }}</p>
                      <p class="partnerDescriptionPost">{{ item.vaevpoint3 }}</p>
                      <p class="partnerDescriptionPost">{{ item.vaevpoint4 }}</p>
                      <p class="partnerDescriptionPost">{{ item.vaevpoint5 }}</p>
                      <p class="partnerDescriptionPost">{{ item.vaevpoint6 }}</p>
                      <p class="partnerDescriptionPost">{{ item.vaevpoint7 }}</p>
                      <v-divider class="mb-5 mt-5"></v-divider>
                      <p class="partnerDescriptionPost">{{ item.org }}</p>
                      <p class="partnerDescriptionPost">{{ item.address }}</p>
                      <p class="partnerDescriptionPost">{{ item.post }}</p>
                      <p class="partnerDescriptionPost">{{ item.country }}</p>
                      <p class="partnerDescriptionPost">{{ item.ceo }}</p>
                      <p class="partnerDescriptionPost">{{ item.vaevperson1 }}</p>
                      <p class="partnerDescriptionPost">{{ item.vaevperson2 }}</p>
                      <p class="partnerDescriptionPost">{{ item.vaevperson3 }}</p>
                      <p class="partnerDescriptionPost">{{ item.phone }}</p>
                      <p class="partnerDescriptionPost">{{ item.email }}</p>
                      <p class="partnerDescriptionPost">{{ item.homepage }}</p>
                      <p class="partnerDescriptionPost">{{ item.vaevlinkedin }}</p>
                      <p class="partnerDescriptionPost">{{ item.vaevfacebook }}</p>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn icon v-if="selectedIndex !== index" @click="openingPartnerExpand(index)">
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
                <v-btn icon v-else @click="closingPartnerExpand()">
                  <v-icon large color="black">mdi-chevron-up</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedIndex: null,
      projectPartners: [
        {
          // PRIOS
          title: "Prios Vest AS (PV)",
          image: "img/prioslogo_PNG big_dark.png",
          link: "https://www.priosvest.no/",
          description: this.$t("partners.prios-description"),
          org: this.$t("partners.prios-org"),
          address: this.$t("partners.prios-address"),
          post: this.$t("partners.prios-post"),
          country: this.$t("partners.prios-country"),
          phone: this.$t("partners.prios-phone"),
          email: this.$t("partners.prios-email"),
          homepage: this.$t("partners.prios-homepage"),
          ceo: this.$t("partners.prios-ceo"),
        },
        {
          // DCOT
          title: this.$t("partners.dcot-title"),
          image: "img/Aketh_en.png",
          link: "https://www.aketh.eu/",
          description: this.$t("partners.dcot-description"),
          org: this.$t("partners.dcot-org"),
          address: this.$t("partners.dcot-address"),
          post: this.$t("partners.dcot-post"),
          country: this.$t("partners.dcot-country"),
          phone: this.$t("partners.dcot-phone"),
          email: this.$t("partners.dcot-email"),
          homepage: this.$t("partners.dcot-homepage"),
        },
        {
          // LEARNMERA
          title: "Learnmera Oy",
          image: "img/cropped-Learnmera.png",
          link: "https://www.learnmera.com/",
          description: this.$t("partners.learnmera-description"),
          org: this.$t("partners.learnmera-org"),
          address: this.$t("partners.learnmera-address"),
          post: this.$t("partners.learnmera-post"),
          country: this.$t("partners.learnmera-country"),
          phone: this.$t("partners.learnmera-phone"),
          email: this.$t("partners.learnmera-email"),
          homepage: this.$t("partners.learnmera-homepage"),
          ceo: this.$t("partners.learnmera-person"),
        },
        {
          // VAEV
          title: this.$t("partners.vaev-title"),
          image: "img/vaevlogo.jpeg",
          link: "https://vaev.at/",
          description: this.$t("partners.vaev-description"),
          vaevpoint1: this.$t("partners.vaev-point1"),
          vaevpoint2: this.$t("partners.vaev-point2"),
          vaevpoint3: this.$t("partners.vaev-point3"),
          vaevpoint4: this.$t("partners.vaev-point4"),
          vaevpoint5: this.$t("partners.vaev-point5"),
          vaevpoint6: this.$t("partners.vaev-point6"),
          vaevpoint7: this.$t("partners.vaev-point7"),
          org: this.$t("partners.vaev-org"),
          vaevperson1: this.$t("partners.vaev-person1"),
          vaevperson2: this.$t("partners.vaev-person2"),
          vaevperson3: this.$t("partners.vaev-person3"),
          country: this.$t("partners.vaev-country"),
          homepage: this.$t("partners.learnmera-homepage"),
          vaevlinkedin: this.$t("partners.vaev-linkedin"),
          vaevfacebook: this.$t("partners.vaev-facebook"),
        },
        {
          // ARDA
          title: this.$t("partners.arda-title"),
          image: "img/ardalogo.jpeg",
          link: "https://www.ar-da.de",
          description: this.$t("partners.arda-description"),
        },
        {
          // MUAVS
          title: this.$t("partners.muavs-title"),
          image: "img/mersinlogo.jpg",
          link: "https://mersin.edu.tr/academic/vocational-school-of-anamur",
          description: this.$t("partners.muavs-description"),
        },
      ],
    };
  },
  methods: {
    openingPartnerExpand(index) {
      this.selectedIndex = index;
    },

    closingPartnerExpand() {
      this.selectedIndex = null;
    },
  },
};
</script>

<style scoped>
/* Card CSS */
/* .box {
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(to right, #dd9a30 5%, transparent 5%);
  border-image-slice: 1;
} */
.cardBorder {
  border: 3px var(--brand-color-main) solid;
  border-radius: 7px;
  transition: 0.2s ease;
}
.cardBorder:hover {
  border: 3px var(--brand-color-secondary) solid;
}

/* Text styling */
.partnerChapterTitle {
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 46px;
  color: #434343;
}
.partnerTitle {
  font-family: var(--subtitle-font);
  font-weight: var(--subtitle-weight);
  font-size: var(--subtitle-size);
  color: var(--subtitle-color);
}
.partnerDescriptionPre {
  font-family: var(--body-font);
  font-weight: var(--body-weight);
  color: var(--body-color);
  font-size: var(--body-size);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.partnerDescriptionPost {
  font-family: var(--body-font);
  font-weight: var(--body-weight);
  font-size: var(--body-size);
  color: var(--body-color);
  line-height: var(--body-height);
}
</style>
