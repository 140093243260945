<template>
  <v-app>
    <link href="https://fonts.googleapis.com/css?family=Material+Icons+Outlined|Material+Icons+Round" rel="stylesheet" />
    <Sidebar v-if="$vuetify.breakpoint.mdAndDown"></Sidebar>
    <Navbar v-else></Navbar>

    <!-- <NavbarPhone></NavbarPhone> -->
    <v-main>
      <router-view />
    </v-main>

    <Footer></Footer>
  </v-app>
</template>

<script>
import Navbar from "@/components/Global/Navbar.vue";
import NavbarPhone from "@/components/Global/NavbarPhone.vue";
import LanguageTest from "@/components/TestAndDevelopment/LanguageTest.vue";
import SelectLocale from "@/components/TestAndDevelopment/SelectLocale.vue";
import Sidebar from "@/components/Global/Sidebar.vue";
import Footer from "@/components/Global/Footer.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Sidebar,
    NavbarPhone,
    LanguageTest,
    SelectLocale,
    Footer,
  },
  data() {
    return {};
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");
html {
  /* COLORS */
  --brand-color-main: #65009a;
  --brand-color-secondary: #8dc73f;
  --blue: #4398e8;

  /* TEXT */
  --headline-font: "quicksand", sans-serif;
  --headline-size: 48px;
  --headline-weight: bold;
  --headline-color: #434343;

  --title-font: "quicksand", sans-serif;
  --title-size: 34px;
  --title-weight: bold;
  --title-color: #434343;

  --subtitle-font: "quicksand", sans-serif;
  --subtitle-size: 28px;
  --subtitle-weight: bold;
  --subtitle-color: #434343;

  --body-font: "quicksand", sans-serif;
  --body-size: 19px;
  --body-weight: normal;
  --body-color: #6a6a6a;
  --body-height: 1.4;

  --caption-font: "quicksand", sans-serif;
  --caption-size: 14px;
  --caption-weight: bold;
  --caption-color: #6a6a6a;
}

/* ===== TEXT CLASSES ===== */

.caHeadline {
  font-size: var(--headline-size);
  color: var(--headline-color);
  font-weight: var(--headline-weight);
  font-family: var(--headline-font);
}
.caTitle {
  font-size: var(--title-size);
  color: var(--title-color);
  font-weight: var(--title-weight);
  font-family: var(--title-font);
}
.caSubtitle {
  font-size: var(--subtitle-size);
  color: var(--subtitle-color);
  font-weight: var(--subtitle-weight);
  font-family: var(--subtitle-font);
}
.caBody {
  font-size: var(--body-size);
  color: var(--body-color);
  font-weight: var(--body-weight);
  font-family: var(--body-font);
  line-height: var(--body-height);
}
.caCaption {
  font-size: var(--caption-size);
  color: var(--caption-color);
  font-weight: var(--caption-weight);
  font-family: var(--caption-font);
}

a {
  color: #146ed0;
  transition: 0.4s;
}
a:hover {
  color: #65009a;
}
/* ===== COMPONENTS ===== */
::selection {
  background-color: var(--brand-color-secondary);
  color: #1d1d1d;
}
.aboutChip {
  font-size: var(--caption-size) !important;
  font-weight: var(--caption-weight);
  font-family: var(--body-font);
  border-radius: 100px !important;
  background-color: #65009a15 !important;
  padding: 20px 24px !important;
  pointer-events: none;
}
.iconbutton {
  border-radius: 100px;
  height: 45px;
  width: 45px;
  background-size: 100%;
  color: #1d1d1d;
  cursor: pointer;
  transition: 0.2s ease;
  padding: 5px;
  box-shadow: 0px 1px 6px #00000055;
}
.iconbuttontext {
  position: relative;
  font-size: 26px !important;
  transform: translateY(3px);
}
.iconbuttoninsetdiv {
  border: 2px solid var(--brand-color-main) !important;
  border-radius: 100px;
  width: 35px;
  height: 35px;
  transition: ease 0.4s;
}
.iconbutton:hover .iconbuttoninsetdiv {
  border: 2px solid var(--brand-color-secondary) !important;
}
/* Styling the 3 cards close to the intro top */
.pageCard {
  position: relative;
  top: -60%;
  min-height: 160px;
  padding: 10px;
  box-shadow: 0px 2px 16px #00000055 !important;
  /* border: 2px solid var(--brand-color-main) !important; */
  border-radius: 7px !important;
  transition: ease 0.4s;
}
.pageCard:hover {
  /* border: 2px solid var(--brand-color-secondary) !important; */
  box-shadow: 0px 2px 16px #00000080 !important;
}
.pageCard:hover .iconbuttoninsetdiv {
  border: 2px solid var(--brand-color-secondary) !important;
}
.pageCard::after {
  height: 6px;
  background-color: var(--brand-color-main);
  top: 6px;
  width: calc(100% - 12px);
  /* margin: auto 6px auto 6px; */
  left: 6px;
  content: "";
  position: absolute;
  border-radius: 100px;
  transition: ease 0.4s;
}
.pageCard:hover::after {
  background-color: var(--brand-color-secondary);
}
.pageCardDividerBlue {
  background-color: #205072;
  padding: 2px;
}
.pageCardDividerGreen {
  background-color: #329d9c;
  padding: 2px;
}
.pageCardDividerRed {
  background-color: #d83636;
  padding: 2px;
}
.pageCardTitle {
  font-family: "filson-pro", sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: #434343;
}
.pageCardDescription {
  font-family: "Omnes", sans-serif;
  font-weight: regular;
  font-size: 19px;
  color: #6a6a6a;
  /* min-height: 50px; */
  line-height: 1.28;
  margin-top: -15px;
}
/* RSS card */
.rssCard {
  position: relative;
  padding: 10px;
  box-shadow: 0px 2px 16px #00000055 !important;
  border: 2px solid var(--brand-color-main) !important;
  border-radius: 7px !important;
  transition: ease 0.4s;
}
.rssCard:hover {
  /* border: 2px solid var(--brand-color-secondary) !important; */
  box-shadow: 0px 2px 16px #00000080 !important;
}
.rssCard:hover .iconbutton {
  color: #ffffff !important;
}
/* .pageCardArrow {
  color: #205072;
  opacity: 1;
} */
/* Used several places */

/* NEWS */
.newsCard {
  padding: 10px;
  box-shadow: 0px 2px 16px #00000055 !important;
  /* border: 2px solid var(--brand-color-main) !important; */
  border-radius: 7px !important;
  transition: ease 0.4s;
}
.newsCard:hover {
  /* border: 2px solid var(--brand-color-secondary) !important; */
  box-shadow: 0px 2px 16px #00000080 !important;
}
.newsImage {
  width: calc(100% - 4px);
  margin: 2px 0 0 2px;
}
.newsChapterTitle {
  font-family: var(--title-font);
  font-weight: var(--title-weight);
  font-size: var(--title-size);
  color: var(--title-color);
}
.newsCardTitle {
  font-family: var(--subtitle-font);
  font-weight: var(--subtitle-weight);
  font-size: var(--subtitle-size);
  color: var(--subtitle-color);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.3;
}
.newsCardDescription {
  font-family: var(--body-font);
  font-size: var(--body-size);
  line-height: var(--body-height);
  font-weight: regular;
  text-align: left;
  color: var(--body-color);
  opacity: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
/* .cardButton {
  border-radius: 100px;
  transition: 0.2s ease;
}
.newsCard:hover .cardButton {
  background-color: var(--brand-color-secondary) !important;
  color: #1d1d1d;
} */

.cardButton {
  border-radius: 100px;
  color: #1d1d1d;
  cursor: pointer;
  transition: 0.2s ease;
  padding: 5px;
  box-shadow: 0px 1px 6px #00000055;
}
.cardButtonText {
  position: relative;
  font-size: 19px !important;
  transform: translateY(5px);
}
.cardButtonInsetDiv {
  border: 2px solid var(--brand-color-main);
  border-radius: 100px;
  height: 40px;
  transition: ease 0.4s;
}
.newsCard:hover .cardButtonInsetDiv {
  border: 2px solid var(--brand-color-secondary) !important;
}

.mainButton {
  border-radius: 100px !important;
  color: #1d1d1d;
  cursor: pointer;
  transition: 0.2s ease;
  padding: 5px;
  box-shadow: 0px 1px 10px #00000055;
  background-color: white;
}
.mainButtonText {
  position: relative;
  font-size: 19px !important;
  transform: translateY(5px);
}
.mainButtonInsetDiv {
  border: 2px solid var(--brand-color-main);
  border-radius: 100px;
  height: 40px;
  transition: ease 0.4s;
}
.mainButton:hover {
  box-shadow: 0px 1px 10px #00000088;
}
.mainButton:hover .mainButtonInsetDiv {
  border: 2px solid var(--brand-color-secondary) !important;
}
/* See All News / RSS Button */
/* .seeAllButtonText {
  font-family: var(--body-font);
  font-weight: normal;
  font-size: 18px;
  color: #205072;
  opacity: 1;
  text-align: center;
  text-transform: initial;
}
.seeAllButtonBorder {
  border: 3px solid #205072;
  font-family: var(--body-font);
  opacity: 1;
  border-radius: 52px;
}
.seeAllButtonBorder:after {
  border: 3px solid #d6d2d24d;
  content: "";
  position: absolute;
  top: -9px;
  right: -9px;
  bottom: -9px;
  left: -9px;
  border-radius: 4em;
  box-shadow: 0px 2px 12px #00000055;
} */

/* RSS feed */
.rssCardTitle {
  font-family: var(--subtitle-font);
  font-weight: var(--subtitle-weight);
  font-size: var(--subtitle-size);
  color: var(--subtitle-color);
  text-align: left;
  opacity: 1;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.rssCardDescription {
  font-family: var(--body-font);
  font-weight: var(--body-weight);
  font-size: var(--body-size);
  color: var(--body-color);
  opacity: 1;
  text-align: left;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.rssCardDate {
  font-family: var(--caption-font);
  font-weight: normal;
  font-size: var(--caption-size);
  color: var(--caption-color);
  opacity: 1;
  text-align: left;
  white-space: nowrap;
}
</style>
