<template>
  <div :class="$vuetify.breakpoint.lgAndDown ? 'pr-3 pl-3' : ''">
    <v-row class="ma-0 pa-0 mb-15">
      <v-col align="center" class="ma-0 pa-0 mt-15">
        <v-col
          cols="12"
          xl="8"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          class="ma-0 pa-0"
        >
          <v-row class="ma-0 pa-0">
            <v-col align="left" cols="12" class="mt-15">
              <p class="caHeadline">{{ $t("resources.title") }}</p>
            </v-col>
            <v-row class="ma-0 pa-0">
              <v-col
                align="left"
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                xs="12"
                class="ma-0 pa-0"
                v-for="(resourceCard, resourceCarIndexs) in resourceCards"
                :key="resourceCarIndexs"
              >
                <v-hover v-slot:default="{ hover }">
                  <v-card
                    class="grey darken-3 ma-2"
                    style="cursor: pointer"
                    height="100%"
                    :elevation="hover ? 12 : 2"
                    :href="resourceCard.link"
                    target="_blank"
                  >
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12" class="ma-0 pa-0 pb-3">
                        <v-row>
                          <v-col cols="12">
                            <v-img
                              :src="resourceCard.resourceImage"
                              alt="Resource Image"
                              height="auto"
                              contain
                            ></v-img>
                          </v-col>
                          <v-col cols="12" class="ma-0 pa-0 text-center">
                            <p
                              class="pa-0 mb-0 white--text font-weight-bold"
                              style="font-size: 18px"
                            >
                              {{ resourceCard.title }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resourceCards: [
        {
          title: this.$t("resources.Employability"),
          resourceImage: require("@/assets/images/resourceImageOne.png"),
          link: "https://edw.aketh.eu/enrol/index.php?id=8",
        },
        {
          title: this.$t("resources.ictDigitalLiteracySkills"),
          resourceImage: require("@/assets/images/resourceImageTwo.png"),
          link: "https://edw.aketh.eu/enrol/index.php?id=18",
        },
        {
          title: this.$t("resources.entrepreneurshipFinancial"),
          resourceImage: require("@/assets/images/resourceImageThree.png"),
          link: "https://edw.aketh.eu/enrol/index.php?id=12",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped>
.closeIcon {
  cursor: pointer;
  transition: ease 0.2s;
  /* color: var(--brand-color-main); */
}
.closeIcon:hover {
  color: white;
}
.resourceCard {
  border-radius: 7px !important;
  border: 2px solid var(--brand-color-main) !important;
  box-shadow: 0px 2px 16px #00000033 !important;
  transition: ease 0.4s;
}
.resourceCard:hover {
  box-shadow: 0px 2px 16px #00000080 !important;
  border: 2px solid var(--brand-color-secondary) !important;
}
/* .resourceCard:hover .iconbutton {
  color: white;
} */
.newsCardDividerPositioning {
  border-radius: 4px;
  padding: 3px;
  width: calc(100% - 12px);
}
</style>
