<template>
  <v-app-bar app class="navbarStyling" id="navbar" flat>
    <ContactForm ref="OpenContactForm"></ContactForm>
    <!-- <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12" class="pt-0"></v-col> -->
    <v-col cols="12" align="center" class="pl-0">
      <v-col cols="12" align="left" xl="8" lg="10" md="12" sm="12" xs="12" class="pl-0">
        <v-row>
          <v-col cols="12" xl="3" lg="3" md="3" sm="3" xs="3" align-self="center">
            <a href="/">
              <img
                id="logo"
                style="height: 120px; transition: 0.3s; margin-top: 7px"
                src="/img/EDWlogowhite.png"
                alt="Empowering disadvantaged women logo"
              />
            </a>
          </v-col>
          <!-- <v-spacer></v-spacer> -->
          <v-col cols="12" xl="9" lg="9" md="9" sm="9" xs="9" align-self="center" align="right" class="pr-0">
            <v-row id="navcontent" class="navbar" align="center" justify="end">
              <a href="/" data-pathname="/" class="navLink navLinkFadeUp">{{ $t("navbar.home") }}</a>
              <!-- <a href="/about" data-pathname="/about" class="navLink navLinkFadeUp">About</a> -->
              <a href="/partners" data-pathname="/partners" class="navLink navLinkFadeUp">{{ $t("navbar.partners") }}</a>
              <a href="/news" data-pathname="/news" class="navLink navLinkFadeUp">{{ $t("navbar.news") }}</a>
              <!-- <a href="/rss" data-pathname="/rss" class="navLink navLinkFadeUp">RSS</a> -->
              <!-- <a href="/trainingmodules" data-pathname="/trainingmodules" class="navLink navLinkFadeUp">Training Modules</a> -->
              <a href="/resources" data-pathname="/resources" class="navLink navLinkFadeUp">{{ $t("navbar.resources") }}</a>
              <a href="/contact" data-pathname="/contact" class="navLink navLinkFadeUp">{{ $t("navbar.contact") }}</a>
              <!-- <a @click="$refs.OpenContactForm.openContactDialog()" class="navLink navLinkFadeUp">{{ $t("navbar.contact") }}</a> -->
              <span class="navLink mr-0">
                <LanguageSelection></LanguageSelection>
              </span>
              <!-- <span class="contactButton contactButtonFadeUp" @click="$refs.OpenContactForm.openContactDialog()">Contact</span> -->
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
    <!-- <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12" class="ml-3 pt-0"></v-col> -->
  </v-app-bar>
</template>

<script>
document.addEventListener("DOMContentLoaded", () => {
  document.getElementById("navbar").style.height = "150px";
});

window.onscroll = function () {
  scrollFunction();
};
function scrollFunction() {
  if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
    document.getElementById("logo").style.height = "90px";
    document.getElementById("navbar").style.padding = "20px 0 20px 0";
    document.getElementById("navbar").style.height = "120px";
  } else {
    document.getElementById("logo").style.height = "120px";
    document.getElementById("navbar").style.padding = "35px 0 50px 0";
    document.getElementById("navbar").style.height = "150px";
  }
}
document.addEventListener("DOMContentLoaded", () => {
  var links = document.getElementsByClassName("navLink");
  var URL = window.location.pathname;
  URL = URL.substring(URL.lastIndexOf("/"));
  for (var i = 0; i < links.length; i++) {
    if (links[i].dataset.pathname == URL) {
      links[i].classList.add("activeNavLink");
    }
  }
});
import ContactForm from "@/components/Global/ContactUs.vue";
import LanguageSelection from "@/components/SelectLocale.vue";

export default {
  components: {
    ContactForm,
    LanguageSelection,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.navLink {
  padding: 15px 0px;
  margin: 0px 15px;
  display: inline-block;
  position: relative;
  color: var(--body-color);
}
.navLink:hover {
  text-decoration: none;
}
.navLink::before {
  transition: 300ms;
  height: 5px;
  content: "";
  position: absolute;
  background-color: var(--brand-color-secondary);
  border-radius: 100px;
}
.navLinkFadeUp::before {
  width: 100%;
  bottom: 5px;
  opacity: 0;
}
.navLinkFadeUp:hover::before {
  bottom: 10px;
  opacity: 1;
}
/* .navLinkFadeUp:active::before {
  bottom: 12px;
  opacity: 1;
} */
.activeNavLink {
  position: relative;
}
.activeNavLink::before {
  bottom: 10px;
  height: 5px;
  content: "";
  position: absolute;
  background-color: var(--brand-color-main);
  border-radius: 100px;
  opacity: 1;
}
.navbarStyling {
  /* height: 150px !important; */
  padding: 35px 0 50px 0;
  background-color: #ffffff !important;
  box-shadow: 0px 2px 20px #00000029 !important;
  transition: 0.2s;
}
.navbarStyling::after {
  height: 6px;
  background-color: var(--brand-color-main);
  top: calc(100% - 18px);
  width: calc(100% - 24px);
  margin: auto 12px auto 12px;
  content: "";
  position: absolute;
  border-radius: 100px;
}
a {
  padding: 14px;
  /* color: #6a6a6a; */
  text-decoration: none;
  font-size: var(--body-size);
  font-family: var(--body-font);
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a:active {
  background-color: none;
}

/* Contact Button */
/* .contactButton {
  font-size: var(--body-size);
  font-family: var(--body-font);
  color: var(--body-color);
  line-height: 1em;
} */

/* .contactButton:hover {
  text-decoration: underline;
  cursor: pointer;
} */
</style>
