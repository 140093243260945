<template>
  <div>
    <NewsDialog ref="openingNewsDialog"></NewsDialog>
    <RssDialog ref="openingRssDialog"></RssDialog>

    <!-- Snackbar for Contact Form -->
    <v-snackbar v-model="contactToast" dark color="success">
      <span>The message has been sent!</span>
      <v-btn
        class="float-right"
        x-small
        dark
        icon
        @click="contactToast = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <!-- Home Page Content -->
    <v-row style="width: calc(100% - 2px); margin: 0 11px 0 1px">
      <v-col
        cols="12"
        v-if="$vuetify.breakpoint.xlOnly"
        style="margin-top: 62px"
      ></v-col>
      <v-col
        cols="12"
        v-else-if="$vuetify.breakpoint.lgOnly"
        style="margin-top: 62px"
      ></v-col>
      <v-col
        cols="12"
        v-else-if="$vuetify.breakpoint.mdOnly"
        style="margin-top: 24px"
      ></v-col>
      <v-col
        cols="12"
        v-else-if="$vuetify.breakpoint.smOnly"
        style="margin-top: 32px"
      ></v-col>
      <v-col
        cols="12"
        v-else-if="$vuetify.breakpoint.xsOnly"
        style="margin-top: 32px"
      ></v-col>
      <!-- Intro Image -->
      <v-col cols="12" class="pb-0">
        <v-card elevation="0" style="border-radius: 7px">
          <v-img
            src="/img/EDWhero.jpg"
            alt="Three people with notepads"
            height="650"
          >
            <p
              v-if="$vuetify.breakpoint.mdAndDown"
              class="introDescriptionPhone"
            >
              {{ $t("home.hero-subtitle") }}
            </p>
            <p v-else class="caBody introDescription mb-0">
              {{ $t("home.hero-subtitle") }}
            </p>
            <p
              v-if="$vuetify.breakpoint.mdAndDown"
              class="caHeadline introTitlePhone"
            >
              {{ $t("home.hero-title") }}
            </p>
            <p v-else class="caHeadline introTitle">
              {{ $t("home.hero-title") }}
            </p>
          </v-img>
        </v-card>
      </v-col>

      <!-- 3 Cards in center of cards -->
      <v-col cols="12" align="center">
        <v-col cols="12" xl="8" lg="12" md="12" sm="12" xs="12" class="pt-0">
          <v-row>
            <v-col
              cols="12"
              align="left"
              xl="4"
              lg="4"
              md="6"
              sm="12"
              xs="12"
              v-for="(pageCard, pageCardIndex) in pageCards"
              :key="pageCardIndex"
            >
              <v-card
                class="pageCard pa-4 pt-6"
                :to="pageCard.link"
                height="100%"
                style="position: relative"
              >
                <v-row justify="space-between">
                  <v-col cols="8">
                    <p class="mt-1 mb-3 caSubtitle" style="white-space: nowrap">
                      {{ pageCard.name }}
                    </p>
                  </v-col>
                  <v-col cols="4" class="flex-shrink-1" align="right">
                    <button v-if="pageCardIndex == 0" class="iconbutton">
                      <div class="iconbuttoninsetdiv">
                        <span class="material-icons-round iconbuttontext"
                          >east</span
                        >
                      </div>
                    </button>
                    <button v-if="pageCardIndex == 1" class="iconbutton">
                      <div class="iconbuttoninsetdiv">
                        <span class="material-icons-round iconbuttontext"
                          >east</span
                        >
                      </div>
                    </button>
                    <button v-if="pageCardIndex == 2" class="iconbutton">
                      <div class="iconbuttoninsetdiv">
                        <span class="material-icons-round iconbuttontext"
                          >east</span
                        >
                      </div>
                    </button>
                  </v-col>
                </v-row>
                <p class="caBody">{{ pageCard.description }}</p>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-col>

      <!-- Info Text -->
      <!-- <v-col cols="12" align="center">
        <v-col cols="12" align="center" xl="4" lg="5" md="8" sm="10" xs="12">
          <p class="caTitle mb-2">Project introduction</p>
          <v-img src="/img/EDWswoosh.svg" style="width: 108px; height: 15px" class="mb-3"></v-img>
          <p class="caBody">
            We aim to provide special learning opportunities to low-skilled adults and women, to encourage women into education by removing location
            and time restrictions, and by increasing women's economic empowerment/basic skills and competencies through remote education.
          </p>
        </v-col>
      </v-col> -->
      <v-col v-if="$vuetify.breakpoint.smAndUp" cols="12" align="center">
        <v-col
          cols="12"
          align="left"
          xl="8"
          lg="10"
          md="12"
          sm="12"
          xs="12"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-0' : 'mt-5'"
        >
          <v-row>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              align-self="center"
              class="pt-0"
              order-xl="first"
              order-lg="first"
              order-md="first"
              order-sm="last"
              order-xs="last"
            >
              <p class="caHeadline mb-1">{{ $t("home.aboutproject-title") }}</p>
              <v-img
                src="/img/EDWswoosh.svg"
                style="width: 108px; height: 15px"
                class="mb-3"
              ></v-img>
              <p class="caBody mb-1 mt-4">
                {{ $t("home.aboutproject-text") }}
              </p>
              <v-chip class="aboutChip mr-6 mt-6">{{
                $t("home.aboutproject-chip1")
              }}</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">{{
                $t("home.aboutproject-chip2")
              }}</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">{{
                $t("home.aboutproject-chip3")
              }}</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">{{
                $t("home.aboutproject-chip4")
              }}</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">{{
                $t("home.aboutproject-chip5")
              }}</v-chip>
            </v-col>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              class="pt-0"
              order-xl="last"
              order-lg="last"
              order-md="last"
              order-sm="first"
              order-xs="first"
            >
              <div>
                <v-img
                  src="/img/about-1.jpg"
                  alt="Woman in an office taking notes"
                ></v-img>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- THE GOAL -->
      <v-col
        v-if="$vuetify.breakpoint.smAndUp"
        cols="12"
        align="center"
        class="mt-15"
      >
        <v-col
          cols="12"
          align="left"
          xl="8"
          lg="10"
          md="12"
          sm="12"
          xs="12"
          class="mt-15"
        >
          <v-row>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              align-self="center"
              class="pt-0"
              order-xl="first"
              order-lg="first"
              order-md="first"
              order-sm="last"
              order-xs="last"
            >
              <div>
                <v-img
                  src="/img/about-2.jpg"
                  alt="Woman on a bench using a laptop"
                ></v-img>
              </div>
            </v-col>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              align-self="center"
              class="pt-0"
              order-xl="last"
              order-lg="last"
              order-md="last"
              order-sm="first"
              order-xs="first"
            >
              <p class="caTitle mb-2">{{ $t("home.ourfocus-title") }}</p>
              <v-img
                src="/img/EDWswoosh.svg"
                style="width: 108px; height: 15px"
                class="mb-3"
              ></v-img>
              <p class="caBody mt-4">{{ $t("home.ourfocus-text") }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- FOR WHOM -->
      <v-col
        v-if="$vuetify.breakpoint.smAndUp"
        cols="12"
        align="center"
        class="mt-15"
      >
        <v-col
          cols="12"
          align="left"
          xl="8"
          lg="10"
          md="12"
          sm="12"
          xs="12"
          class="mt-15"
        >
          <v-row>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              align-self="center"
              class="pt-0"
              order-xl="first"
              order-lg="first"
              order-md="first"
              order-sm="last"
              order-xs="last"
            >
              <p class="caTitle mb-2">{{ $t("home.forwhom-title") }}</p>
              <v-img
                src="/img/EDWswoosh.svg"
                style="width: 108px; height: 15px"
                class="mb-3"
              ></v-img>
              <p class="caBody mt-4">{{ $t("home.forwhom-text") }}</p>
              <p class="caBody">{{ $t("home.forwhom-partnership") }}</p>
            </v-col>
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              xs="12"
              class="pt-0"
              order-xl="last"
              order-lg="last"
              order-md="last"
              order-sm="first"
              order-xs="first"
            >
              <div>
                <v-img
                  src="/img/about-3.jpg"
                  alt="Woman picking colors from examples"
                ></v-img>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- ===== POINTS ===== -->
      <v-col v-if="$vuetify.breakpoint.smAndUp" cols="12" align="center">
        <v-col
          cols="12"
          align="center"
          xl="6"
          lg="8"
          md="10"
          sm="11"
          xs="12"
          style="background-color: #f5f5f5; border-radius: 7px"
          class="pa-10 mt-15"
        >
          <p class="caTitle mb-2">{{ $t("home.ourgoals-title") }}</p>
          <v-img
            src="/img/EDWswoosh.svg"
            style="width: 108px; height: 15px"
            class="mb-3"
          ></v-img>
          <v-col cols="12" align="left">
            <p class="caBody">{{ $t("home.ourgoals-point1") }}</p>
            <p class="caBody">{{ $t("home.ourgoals-point2") }}</p>
            <p class="caBody">{{ $t("home.ourgoals-point3") }}</p>
            <p class="caBody">{{ $t("home.ourgoals-point4") }}</p>
            <p class="caBody">{{ $t("home.ourgoals-point5") }}</p>
            <p class="caBody">{{ $t("home.ourgoals-point6") }}</p>
            <p class="caBody">{{ $t("home.ourgoals-point7") }}</p>
            <p class="caBody">{{ $t("home.ourgoals-point8") }}</p>
          </v-col>
          <a href="/contact">
            <button class="mainButton">
              <div class="mainButtonInsetDiv pr-6 pl-6">
                <div class="mainButtonText">{{ $t("contact.contact us") }}</div>
              </div>
            </button>
          </a>
        </v-col>
      </v-col>

      <!-- News -->
      <v-col v-if="$vuetify.breakpoint.smAndUp" align="center">
        <v-col cols="12" xl="8" lg="10" md="12" sm="12" xs="12">
          <v-row>
            <!-- Chapter Title -->
            <v-col align="center" cols="12" class="mt-15 pb-0">
              <p class="caTitle mb-2">{{ $t("home.news-title") }}</p>
              <v-img
                src="/img/EDWswoosh.svg"
                style="width: 108px; height: 15px"
                class="mb-3"
              ></v-img>
            </v-col>

            <v-col align="left" cols="12" v-if="allTheNews == ''">
              <p class="text-center title">{{ $t("home.news-nonews") }}</p>
            </v-col>

            <!-- News Cards -->
            <v-col
              align="left"
              cols="12"
              xl="4"
              lg="4"
              md="4"
              sm="12"
              xs="12"
              v-for="(newsData, newsDataIndex) in filteredNews
                .slice(0, 3)
                .reverse()"
              :key="newsDataIndex"
            >
              <v-card
                class="newsCard"
                height="100%"
                @click="getNewsContent(newsData)"
              >
                <v-row>
                  <v-col cols="12" class="pb-4">
                    <v-row>
                      <v-col cols="12">
                        <v-img
                          v-if="newsData.media_url"
                          :src="newsData.media_url"
                          alt="News Image"
                          height="200px"
                          cover
                          class="newsImage"
                        ></v-img>
                        <div
                          v-else
                          style="
                            height: 200px;
                            width: auto;
                            background-color: #d1d1d1;
                            margin: 0px 15px 0px 15px;
                          "
                        ></div>
                      </v-col>
                      <v-col cols="12" style="" class="pa-3">
                        <p class="pl-2 mb-0 mb-2 newsCardTitle">
                          {{ newsData.title }}
                        </p>
                        <p class="pa-2 pb-0 mb-2 newsCardDescription">
                          {{ newsData.description }}
                        </p>
                      </v-col>
                      <v-col cols="12" class="pt-0 mt-0 ml-2">
                        <button class="cardButton mb-2">
                          <div class="cardButtonInsetDiv pr-6 pl-6">
                            <div class="cardButtonText">
                              {{ $t("home.news-morebutton") }}
                            </div>
                          </div>
                        </button>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- See all news button - go to page -->
      <v-col v-if="$vuetify.breakpoint.smAndUp" cols="12" align="center">
        <!-- <v-btn class="seeAllButtonBorder seeAllButtonText" rounded to="/news" v-if="allTheNews != ''"> See all news </v-btn> -->
        <a href="/news">
          <button class="mainButton mb-15" to="/news" v-if="allTheNews != ''">
            <div class="mainButtonInsetDiv pr-6 pl-6">
              <div class="mainButtonText">{{ $t("home.news-mainbutton") }}</div>
            </div>
          </button>
        </a>
      </v-col>

      <!-- RSS -->
      <!-- <v-col align="center">
        <v-col cols="12" xl="8" lg="10" md="12" sm="12" xs="12">
          <v-row>
            <v-col align="left" cols="12" class="pt-15 mt-15 pb-0">
              <p class="caTitle">RSS feed</p>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="12" sm="12" xs="12" v-for="(rssFeed, rssFeedIndex) in fakeRSSfeed" :key="rssFeedIndex">
              <v-card class="rssCard" style="padding: 6px" height="130px" @click="$refs.openingRssDialog.openRssDialog(rssFeed)">
                <div class="d-flex"> -->
      <!-- Image -->
      <!-- <div class="pa-0" style="background-color: #d1d1d1; border-radius: 7px; height: 114px; width: 114px">
                    <v-img v-if="rssFeed.image" :src="rssFeed.image" alt="News Image" style="width: 114px; height: 114px" contain></v-img>
                    <div v-else style="width: 114px"></div> -->
      <!-- <div v-else style="width: 100px; height: 100px; background-color: #d1d1d1"></div> -->
      <!-- </div> -->
      <!-- Title and Content -->
      <!-- <v-row class="pa-2 pl-4">
                    <v-col :cols="$vuetify.breakpoint.xs ? '12' : '9'" class="">
                      <p class="rssCardTitle mb-0">{{ rssFeed.title }}</p>
                      <p class="rssCardDescription mb-0">{{ rssFeed.content }}</p>
                    </v-col> -->
      <!-- Date and Arrow -->
      <!-- <v-col align="right" cols="3" :style="$vuetify.breakpoint.xs ? 'display: none' : ''">
                      <p class="rssCardDate text-right" style="margin-bottom: 6px">{{ rssFeed.date }}</p>
                      <button class="iconbutton" style="margin-right: -2px; background-image: url('/img/icon button bg green.svg')">
                        <span class="material-icons-round iconbuttontext">east</span>
                      </button>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-col> -->
      <!-- See all RSS button - go to page -->
      <!-- <v-col cols="12" align="center" class="mb-15"> -->
      <!-- <v-btn class="seeAllButtonBorder seeAllButtonText" rounded to="/news" v-if="allTheNews != ''"> See all news </v-btn> -->
      <!-- <button class="mainbutton" to="/rss">
          <p class="mainbuttontext">View RSS feed</p>
        </button>
      </v-col> -->
      <!-- <v-col cols="12" class="mb-15 pb-15" align="center">
        <v-btn class="seeAllButtonBorder seeAllButtonText" rounded to="/rss"> View RSS feed </v-btn>
      </v-col> -->
    </v-row>
    <!-- =============== PHONE =============== -->

    <v-row v-if="$vuetify.breakpoint.xsOnly">
      <!-- ===== About Project ===== -->
      <v-col cols="12" align="center" class="">
        <v-col
          cols="12"
          align="left"
          xl="8"
          lg="10"
          md="12"
          sm="12"
          xs="12"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-0' : 'mt-15'"
        >
          <v-row>
            <v-col cols="12" class="pa-0">
              <div>
                <v-img
                  src="/img/about-1.jpg"
                  alt="Woman in an office taking notes"
                ></v-img>
              </div>
            </v-col>
            <v-col cols="12">
              <p class="caHeadline">{{ $t("home.aboutproject-title") }}</p>
              <p class="caBody mt-6">
                {{ $t("home.aboutproject-text") }}
              </p>
              <v-chip class="aboutChip mr-6 mt-6">{{
                $t("home.aboutproject-chip1")
              }}</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">{{
                $t("home.aboutproject-chip2")
              }}</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">{{
                $t("home.aboutproject-chip3")
              }}</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">{{
                $t("home.aboutproject-chip4")
              }}</v-chip>
              <v-chip class="aboutChip mr-6 mt-6">{{
                $t("home.aboutproject-chip5")
              }}</v-chip>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- THE GOAL -->
      <v-col cols="12" align="center" class="mt-5">
        <v-col
          cols="12"
          align="left"
          xl="8"
          lg="10"
          md="12"
          sm="12"
          xs="12"
          class="mt-15"
        >
          <v-row>
            <v-col cols="12" align-self="center" class="pa-0">
              <div>
                <v-img
                  src="/img/about-2.jpg"
                  alt="Woman on a bench using a laptop"
                ></v-img>
              </div>
            </v-col>
            <v-col cols="12" align-self="center" class="pt-6">
              <p class="caTitle">{{ $t("home.ourfocus-title") }}</p>
              <p class="caBody mt-4">{{ $t("home.ourfocus-text") }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- FOR WHOM -->
      <v-col cols="12" align="center" class="mt-5">
        <v-col
          cols="12"
          align="left"
          xl="8"
          lg="10"
          md="12"
          sm="12"
          xs="12"
          class="mt-15"
        >
          <v-row>
            <v-col cols="12" class="pa-0">
              <div>
                <v-img
                  src="/img/about-3.jpg"
                  alt="Woman picking colors from examples"
                ></v-img>
              </div>
            </v-col>
            <v-col cols="12" align-self="center" class="pt-6">
              <p class="caTitle">{{ $t("home.forwhom-title") }}</p>
              <p class="caBody mt-4">{{ $t("home.forwhom-text") }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
      <!-- OUR GOALS -->
      <v-col cols="12" align="center">
        <v-col
          cols="12"
          align="center"
          xl="6"
          lg="8"
          md="10"
          sm="11"
          xs="12"
          style="background-color: #f5f5f5; border-radius: 7px"
          class="pa-10 mt-15"
        >
          <p class="caTitle mb-2">{{ $t("home.ourgoals-title") }}</p>
          <v-img
            src="/img/EDWswoosh.svg"
            style="width: 108px; height: 15px"
            class="mb-3"
          ></v-img>
          <v-col cols="12" align="left">
            <p class="caBody">{{ $t("home.ourgoals-point1") }}</p>
            <p class="caBody">{{ $t("home.ourgoals-point2") }}</p>
            <p class="caBody">{{ $t("home.ourgoals-point3") }}</p>
            <p class="caBody">{{ $t("home.ourgoals-point4") }}</p>
            <p class="caBody">{{ $t("home.ourgoals-point5") }}</p>
            <p class="caBody">{{ $t("home.ourgoals-point6") }}</p>
            <p class="caBody">{{ $t("home.ourgoals-point7") }}</p>
            <p class="caBody">{{ $t("home.ourgoals-point8") }}</p>
          </v-col>
          <a href="/contact">
            <button class="mainButton">
              <div class="mainButtonInsetDiv pr-6 pl-6">
                <div class="mainButtonText">{{ $t("contact.contact us") }}</div>
              </div>
            </button>
          </a>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NewsDialog from "@/components/dialogs/newsDialog.vue";
import RssDialog from "@/components/dialogs/rssFeedDialog.vue";
import RssTest from "@/components/rss/rssTest.vue";
import { access } from "fs";
export default {
  name: "Home",
  components: {
    NewsDialog,
    RssDialog,
    RssTest,
  },
  data() {
    return {
      accessKey: window.btoa("bac436b32a36431bb437b9509b6d3495"),
      // accessKey: process.env.VUE_APP_API_KEY,
      tenant: 112,
      allTheNews: [],
      theNewsContent: [],
      colorArr: ["#205072", "#329D9C", "#D83636", "#DD9A30"],
      contactToast: false,
      // Contact Form
      contactForm: {
        name: "",
        email: "",
        message: "",
        subject: "",
        sentFrom: "Mathgan",
      },
      languageSelected: "en",
      languageCodeToName: {
        // Mapping object for language codes to names
        en: "english",
        nb: "norwegian",
        fi: "finnish",
        gr: "greek",
        de: "german",
        tr: "turkish",
      },
      // Rules for the contact Form
      rules: {
        requiredField: (value) => !!value || "This field is required",
        requiredName: (value) => !!value || "Your name is required",
        requiredEmail: (value) =>
          !!value || "A propper email adress is required",
        emailRequirement: (value) =>
          /.+@.+\..+/.test(value) || "A proper email address is required",
      },
      contactToast: false,
      isContactFormValid: true,
      // In Page Pages Cards
      pageCards: [
        {
          name: this.$t("home.pagecard-news"),
          color: "",
          link: "/news",
          description: this.$t("home.pagecard-newsdescription"),
        },
        {
          name: this.$t("home.pagecard-resources"),
          color: "",
          link: "/resources",
          description: this.$t("home.pagecard-resourcesdescription"),
        },
        {
          name: this.$t("home.pagecard-partners"),
          color: "",
          link: "/partners",
          description: this.$t("home.pagecard-partnersdescription"),
        },
      ],
      fakeRSSfeed: [
        {
          title: "RSS feed title",
          image: "",
          content:
            "Lorem ipsum dolor sit amet, eligendi distinctio iste Iste quis rerum",
          date: "02.03.2022",
        },
        {
          title: "RSS feed title",
          image: "",
          content:
            "Lorem ipsum dolor sit amet, eligendi distinctio iste Iste quis rerum",
          date: "02.03.2022",
        },
        {
          title: "RSS feed title",
          image: "",
          content:
            "Lorem ipsum dolor sit amet, eligendi distinctio iste Iste quis rerum",
          date: "02.03.2022",
        },
        {
          title: "RSS feed title",
          image: "",
          content:
            "Lorem ipsum dolor sit amet, eligendi distinctio iste Iste quis rerum",
          date: "02.03.2022",
        },
      ],
    };
  },
  mounted() {
    this.getAllNews();
    this.getLanguageSelected();
  },
  methods: {
    // funcy() {
    //   console.log(accessKey);
    // },
    // Form: Clear all data
    clearForm() {
      this.$refs.form.reset();
    },
    // Form: Send Data
    postContactForm() {
      const ContactForm = {
        name: this.contactForm.name,
        email: this.contactForm.email,
        subject: this.contactForm.subject,
        message: this.contactForm.message,
        sentFrom: this.contactForm.sentFrom,
      };
      console.log("Sending Contact Form", ContactForm);
      this.messageSent();
      this.clearForm();
    },
    // Form: Notification that message have been sent, through Toast.
    messageSent() {
      this.contactToast = true;
    },

    // Get all news made for this tenant
    getAllNews() {
      this.$http
        .get(
          `https://app.followup.prios.no/api/resource_management/news?mode=getpublicnews&tenant_id=${this.tenant}`,
          {
            headers: { Tempaccess: this.accessKey },
          }
        )
        .then((response) => {
          this.allTheNews = response.data;
          console.log("Responsen", response.data);
        });
    },

    // Get all the content of the clicked news
    getNewsContent(data) {
      this.$http
        .get(
          `https://app.followup.prios.no/api/resource_management/news_content?mode=getpublicnews&news_id=${data.id}`,
          {
            headers: { Tempaccess: this.accessKey },
          }
        )
        .then((response) => {
          this.theNewsContent = response.data;
          console.log("Responsen", response.data);
        })
        .then((responsen) => {
          this.openTheDialog(data, this.theNewsContent);
        });
    },

    // Open the Dialog while passing the correct data
    openTheDialog(originalData, contentData) {
      this.$refs.openingNewsDialog.openNewsDialog(originalData, contentData);
    },

    // Get language selected in localstoragekey language, set english as default
    getLanguageSelected() {
      if (localStorage.getItem("language") == null) {
        this.languageSelected = "en";
      } else {
        this.languageSelected = localStorage.getItem("language");
      }
    },
  },
  computed: {
    // Filtered news based on languageSelected
    filteredNews() {
      const languageName = this.languageCodeToName[this.languageSelected];
      if (!languageName) return []; // If language code not found, return empty array

      return this.allTheNews.filter((newsItem) => {
        // Assuming the language name is stored in 'language' key of newsItem
        return newsItem.language.toLowerCase() === languageName.toLowerCase();
      });
    },
  },
};
</script>

<style scoped>
/* General Page */
/* .homePageWidth {
  width: 90vw;
  margin: auto auto;
} */

/* Intro Classes for box and text at the top */
/* .introImage {
  height:650px;
  background-color: rgb(213, 211, 211);
} */
.introTitle {
  margin-left: 17%;
  margin-top: 0;
  color: white;
  text-shadow: 1px 1px 4px #000000;
}
.introTitlePhone {
  font-size: 36px;
  color: white;
  opacity: 1;
  margin-left: 5%;
  /* margin-top: 50%; */
  text-shadow: 1px 1px 2px #000000;
}
.introDescription {
  font-size: 28px;
  font-weight: bold;
  color: white;
  margin-left: 17%;
  margin-right: 15%;
  margin-top: 12%;
  text-shadow: 1px 1px 2px #000000;
}

.introDescriptionPhone {
  font-size: 22px;
  color: white;
  font-family: var(--body-font);
  font-weight: bold;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 40%;
  text-shadow: 1px 1px 4px #000000;
}
</style>
